<template>
    <div>
        <b-row class="m-0">
            <b-col class="p-0 me-3">
                <div class="bg-white rounded">
                    <div class="cardHeader d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <span class="cardnumber rounded-circle bg-green">01</span>
                            <div class="fs-5 fw-semibold text-grey">Apresentação</div>   
                        </div>
                        <div>
                            <!-- <b-button variant="green" class="text-white" v-b-modal.modalPreviewWhatsapp>Visualizar</b-button> -->
                        </div>
                    </div>
                    <div class="cardContent w-100" v-if="menu">
                        <div class="position-relative">
                            <b-form-group label="Cabeçalho:" label-class="text-purple fw-bold" v-if="menu.header">
                                <b-form-radio-group class="d-flex mb-1" v-model="menu.header.type">
                                    <b-form-radio class="d-inline-flex me-3" value="text">
                                        <div class="ms-2">Texto</div>
                                    </b-form-radio>
                                    <b-form-radio class="d-inline-flex me-3" value="image">
                                        <div class="ms-2">Imagem</div>
                                    </b-form-radio>
                                </b-form-radio-group>
                                <div class="position-relative" v-if="menu.header.type == 'text'">
                                    <b-form-input class="ps-4 pe-5" v-model="menu.header.text" placeholder="Escreva seu texto de cabeçalho" maxlength="60" />
                                    <b-icon class="toggle-emoji darkenTextOnHover mb-1" @click="toggleDialogEmojiHeader" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                    <VEmojiPicker @select="selectEmojiHeader" lang="pt-BR" v-show="showDialogHeader" :i18n="i18n" />
                                </div>
                                <div class="text-danger small-text mt-1" v-if="menu.header?.type == 'text' && menu.header?.text?.length >= 60">
                                    O limite de caracteres (60) foi atingido!
                                </div>
                                <div class="addimage" v-else-if="menu.header.type == 'image'">
                                    <b-form-file id="menuImgUpload" style="display:none" accept="image/*" @input="selectMenuImage"></b-form-file>
                                    <label for='menuImgUpload' class="w-100">
                                        <div class="cardpresentationimg rounded" v-if="!menu.header.image?.link">
                                            <div class="d-flex flex-column align-items-center">
                                                <b-icon class="cardpresentationicon" icon="image"></b-icon>
                                                <div class="cardpresentationtext">Adicionar Imagem</div>
                                            </div>
                                        </div>
                                        <div style="max-height:325px;" role="button" v-else>
                                            <b-img class="rounded" style="max-height: 325px; width: 100%" :src="menu.header.image.link"></b-img>
                                        </div>
                                    </label>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="position-relative mt-2">
                            <b-form-group label="Texto:" label-class="text-purple fw-bold" v-if="menu.body">
                                <b-form-textarea class="px-4" v-model="menu.body.text" placeholder="Escreva seu texto" maxlength="1024" rows="5" max-rows="5" no-resize></b-form-textarea>
                                <b-icon class="toggle-emoji darkenTextOnHover" @click="toggleDialogEmojiTextarea" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                <VEmojiPicker @select="selectEmojiTextarea" lang="pt-BR" v-show="showDialogTextarea" :i18n="i18n" />
                            </b-form-group>
                        </div>
                        <div class="text-danger small-text mt-1" v-if="menu.body?.text?.length >= 1024">
                            O limite de caracteres (1024) foi atingido!
                        </div>
                        <div class="position-relative mt-2">
                            <b-form-group label="Rodapé:" label-class="text-purple fw-bold" v-if="menu.footer">
                                <b-form-input class="ps-4 pe-5" v-model="menu.footer.text" placeholder="Escreva seu texto de rodapé" maxlength="60" />
                                <b-icon class="toggle-emoji darkenTextOnHover mb-1" @click="toggleDialogEmojiFooter" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                <VEmojiPicker @select="selectEmojiFooter" lang="pt-BR" v-show="showDialogFooter" :i18n="i18n" />
                            </b-form-group>
                        </div>
                        <div class="text-danger small-text mt-1" v-if="menu.footer?.text?.length >= 60">
                            O limite de caracteres (60) foi atingido!
                        </div>
                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <div>
                                <!-- <b-form-checkbox class="cardpresentationcheckbox">Texto</b-form-checkbox> -->
                            </div>
                            <div>
                                <!-- <b-button class="managemenubtngreen" v-b-modal.modal-evasivas>Evasivas</b-button> -->
                                <b-button class="managemenubtnpink" v-b-modal.modal-tags>Tags</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col class="p-0 ms-2">
                <div class="bg-white rounded">
                    <div class="cardHeader d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <span class="cardnumber rounded-circle bg-green">02</span>
                            <div class="fs-5 fw-semibold text-grey">Botões</div>   
                        </div>
                        <div>
                            <!-- <b-button variant="green" class="text-white" v-b-modal.modalPreviewWhatsapp>Visualizar</b-button> -->
                        </div>
                    </div>
                    <div class="cardContent w-100" v-if="menu.action">
                        <b-button class="managemenubtngreen px-3 w-100 mb-3" @click="addButton">Adicionar</b-button>
                        <draggable tag="ul" :list="menu.action.buttons" class="list-group" handle=".handle">
                            <li
                            class="d-flex mb-3"
                            v-for="(item,index) in menu.action.buttons"
                            :key="index"
                            >
                                <b-input-group class="me-2">
                                    <template #append>
                                        <b-button class="handle rounded-0 rounded-end" v-b-tooltip.bottom title="Alterar Ordem">
                                            <b-icon icon="arrows-move"></b-icon>
                                        </b-button>
                                    </template>
                                    <b-form-input maxlength="20" v-model="item.reply.title"></b-form-input>
                                </b-input-group>
                                <!-- <b-button variant="green" class="text-white me-2" v-b-tooltip.bottom="{ customClass: 'tooltip-edit' }" title="Editar" v-b-modal.modalButtonConfig @click.prevent="selectButton(item)"><b-icon icon="pencil-square"></b-icon></b-button> -->
                                <b-button variant="red" class="text-white" v-b-tooltip.bottom="{ customClass: 'tooltip-remove' }" title="Remover" v-b-modal.modalRemove @click.prevent="selectButton(item)"><b-icon icon="trash"></b-icon></b-button>
                            </li>
                        </draggable>
                        <!-- <div class="d-flex">
                            <b-input-group class="me-2">
                                <template #append>
                                    <b-button class="rounded-0 rounded-end" v-b-tooltip.bottom title="Alterar Ordem">
                                        <b-icon icon="arrows-move" class="handle"></b-icon>
                                    </b-button>
                                </template>
                                <b-form-input class="border-grey"></b-form-input>
                            </b-input-group>
                            <b-button variant="green" class="text-white me-2" v-b-tooltip.bottom="{ customClass: 'tooltip-edit' }" title="Editar" v-b-modal.modalButtonConfig><b-icon icon="pencil-square"></b-icon></b-button>
                            <b-button variant="red" class="text-white" v-b-tooltip.bottom="{ customClass: 'tooltip-remove' }" title="Remover" v-b-modal.modalRemove><b-icon icon="trash"></b-icon></b-button>
                        </div> -->
                    </div>
                </div>
                <div class="bg-white d-flex align-items-center justify-content-center rounded mt-4 py-4">
                    <b-button class="managemenubtngreen" @click="saveMenu(true)">Avançar</b-button>
                    <b-button class="managemenubtnwhite" @click="goToMain">Cancelar</b-button>
                </div>
            </b-col>
        </b-row>
        <b-modal id="modal-tags" ref="modal-tags" title="Tags" header-class="py-0" body-class="py-0">
            <div class="border-bottom py-2" v-for="item in tags" :key="item.id">
                <b style="text-transform: uppercase">{{item.name}}</b> - {{item.description}}
            </div>
        </b-modal>
        <b-modal id="modalButtonConfig" ref="modalButtonConfig" title="Configurações do Botão" header-class="py-0" hide-footer v-if="buttonSelected">
            <b-form-group class="text-secondary" label="Direcionar para:">
                <b-form-select v-model="buttonSelected.action" class="w-100 p-2 border-grey">
                    <b-form-select-option value="redirectDepartment">Departamento</b-form-select-option>
                    <b-form-select-option value="redirectOperator">Operador</b-form-select-option>
                    <b-form-select-option :value="undefined">Não direcionar</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <b-form-group class="mt-2" :label="`Selecione o ${ buttonSelected.action == 'redirectDepartment' ? 'Departamento' : 'Operador' }:`" v-if="buttonSelected.action">
                <b-form-select class="w-100 p-2 border-grey" v-model="buttonSelected.redirectId" v-if="buttonSelected.action=='redirectDepartment'">
                    <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                </b-form-select>
                <b-form-select class="w-100 p-2 border-grey" v-model="buttonSelected.redirectId" v-else-if="buttonSelected.action=='redirectOperator'">
                    <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                </b-form-select>
            </b-form-group>
        </b-modal>
        <b-modal id="modalRemove" ref="modalRemove" :title="`Remover Botão`" header-class="py-0" hide-footer v-if="buttonSelected">
            <div>
                Tem certeza de que deseja remover este botão?
            </div>
            <div class="mt-2">
                <b-button class="managemenubtngreen" @click="removeFromArray(menu.action.buttons, buttonSelected)">Remover</b-button>
                <b-button class="managemenubtnwhite" @click="$bvModal.hide('modalRemove')">Cancelar</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import api from '../services/apiService.js'
import draggable from 'vuedraggable'
import structuredClone from '@ungap/structured-clone';
import { getToken } from '../services/userService.js'

export default {
    components: {
        VEmojiPicker, draggable
    },
    props: ['user'],
    mounted: function () {
        this.getMenus()
        this.getDepartments()
        this.getOperators()
        this.onCloseModal()
    },
    methods: {
        onCloseModal(){
            // this.$root.$on('bv::modal::hide',(bvEvent, modalId) => {
            // })
        },
        async getMenus(){
            const resp = await api.getMenus(this.user.channelId || this.user.roleId)
            console.log('respMenus',resp)
            if(resp.statusCode!=200){
                return this.$emit('msg', {
                    text:'Ocorreu um erro!',
                    success: false,
                })
            }
            if(!resp.menus.length) {
                const menu = { 
                    name: `Menu Botão ${this.user.channelId || this.user.roleId}`,
                    topMenuId: undefined,
                    refObjectMenus: undefined,
                    channelId: this.user.channelId || this.user.roleId,
                    interactive: {
                        type: 'button',
                        header: {
                            type: 'text',
                            text: ''
                        },
                        body: {
                            text: ''
                        },
                        footer: {
                            text: ''
                        },
                        action: {
                            buttons: []
                        }
                    },
                }
                const respCreate = await api.createMenu(menu)
                // console.log('resp createMenu',resp)
                if((respCreate.statusCode != 200 && respCreate.statusCode != 201) || !respCreate.menu){
                    this.$emit('msg', {
                        text: "Ocorreu um erro ao criar menu!",
                        success: false
                    })
                }else{
                    this.menu = respCreate.menu.interactive
                    this.menu._id = respCreate.menu._id
                }
            } else {
                const mainMenuIndex = resp.menus.findIndex(el => !el.topMenuId)
                const menu = resp.menus[mainMenuIndex].interactive
                if(!menu.body)
                    menu.body = { text: '' }
                if(!menu.header)
                    menu.header = { type: 'text', text: '' }
                if(!menu.footer)
                    menu.footer = { text: '' }
                if(!menu.type)
                    menu.type = 'button'
                if(!menu.action)
                    menu.action = { buttons: [] }
                if(!menu.action.buttons || !menu.action.buttons.some(e => e.reply))
                    menu.action.buttons = []
                this.menu = menu
                this.menu._id = resp.menus[mainMenuIndex]._id
            }
            console.log('menu',this.menu)
        },
        toggleDialogEmojiTextarea() {
            this.showDialogTextarea = !this.showDialogTextarea;
        },
        toggleDialogEmojiHeader() {
            this.showDialogHeader = !this.showDialogHeader;
        },
        toggleDialogEmojiFooter() {
            this.showDialogFooter = !this.showDialogFooter;
        },
        toggleDialogEmojiIcon() {
            this.showDialogIcon = !this.showDialogIcon;
        },
        selectEmojiTextarea(emoji) {
            if(!this.menu.body.text)
                this.menu.body.text = ""
            this.menu.body.text += emoji.data
            this.toggleDialogEmojiTextarea()
        },
        selectEmojiHeader(emoji) {
            if(!this.menu.header.text)
                this.menu.header.text = ""
            this.menu.header.text += emoji.data
            this.toggleDialogEmojiHeader()
        },
        selectEmojiFooter(emoji) {
            if(!this.menu.footer.text)
                this.menu.footer.text = ""
            this.menu.footer.text += emoji.data
            this.toggleDialogEmojiFooter()
        },
        async getOperators(){
            let resp = await api.getOperators(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode!=200){
                this.operators = []
                return
            }
            this.operators = resp.operators
        },
        async getDepartments(){
            let resp = await api.getDepartments(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode!=200){
                this.departments = []
                return
            }
            this.departments = resp.departments
        },
        selectButton(button){
            this.buttonSelected = button;
            this.objAux = structuredClone(button);
        },
        addButton() {
            if(this.menu.action.buttons.length >= 3) {
                return this.$emit('msg', {
                    text:'O número máximo (3) de Botões foi atingido!',
                    success: false,
                })
            }
            this.menu.action.buttons.push({
                reply: {
                    title: ""
                }
            })
        },
        async goToMain(){
            this.$router.replace({path:'/'})
        },
        goToMenuCustom(){
            this.$router.replace({path:`/manageMenuCustom`})
        },
        async saveMenu(advance = false) {
            let menuResp = await api.getMenu(this.menu._id)
            if(menuResp.statusCode == 200) {
                let menu = menuResp.menu
                if(!menu.topMenuId) 
                    menu.topMenuId = undefined
                if(!menu.refObjectMenus)
                    menu.refObjectMenus = undefined
                if(!menu.interactive)
                    menu.interactive = { type: 'button', header: {}, action: {} }
                else if (menu.interactive.type != 'button')
                    menu.interactive.type = 'button'
                if(!menu.interactive.header)
                    menu.interactive.header = {}
                if(!menu.interactive.action)
                    menu.interactive.action = {}
                if(!menu.interactive.header.type)
                    menu.interactive.header.type = 'text'
                if(menu.interactive.action.sections) delete menu.interactive.action.sections
                if(menu.interactive.action.button) menu.interactive.action.button = undefined
                menu.menus = ["null"]

                await api.updateMenu({ menus: [ menu ] })
            }
                
            let presentationResp = await this.savePresentation()
            if(presentationResp != 200){
                let msg = {
                    text:'Revise os dados de Apresentação e tente novamente!',
                    success: false,
                }
                return this.$emit('msg',msg) 
            }

            let buttonsResp = await this.saveButtons()
            if(buttonsResp != 200){
                let msg = {
                    text:'Revise os dados de Botões e tente novamente!',
                    success: false,
                }
                return this.$emit('msg',msg) 
            }
            // let msg = {
            //     text:'Menu salvo com sucesso!',
            //     success: true,
            // }
            // this.$emit('msg',msg) 
            if(advance)
                this.goToMenuCustom()
        },
        async savePresentation(){
            if (this.menu.header.type !== 'text' && this.menu.header.text)
                this.menu.header.text = ""
        
            let resp = await api.updatePresentation(
                { textPresentation: this.menu.body.text, textButton: this.menu.action.button, header: this.menu.header, footer: this.menu.footer },
                this.menu._id
            )
            return resp.statusCode
        },
        async saveButtons(){
            let resp = await api.updateButtons(this.menu.action.buttons,this.menu._id)
            // console.log('buttonsResp',resp)
            return resp.statusCode
        },
        removeFromArray(array,element){ // remove option from a section
            let index = array.indexOf(element)
            array.splice(index, 1)
            this.buttonSelected = null
            this.$refs['modalRemove'].hide()
        },
        async selectMenuImage(e){
            console.log(e)
            if (e.size > 10000000) {
                let msg = {
                    text: "O tamanho máximo permitido para envios é de 10 MB",
                    danger: true
                }
                this.$emit('msg',msg)
            }
            const formData = new FormData();
            e.filename = e.name
            formData.append('file',e)
            let typefile = e['type'].split('/')[0]
            let token = getToken()
            const headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'authorization': token, 'clientNumber': this.user.channelId}
            const uploadResp = await api.upload(formData,headers)
            let url = uploadResp.file.uploadDir
            // let obj = {
            //     url,
            //     type: typefile,
            //     filename: e.filename,
            //     ext: e.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
            // }

            this.$set(this.menu.header, 'image', { link: url })
            
            this.menu.action.sections.push({
                rows: [],
                title: ''
            })
            this.menu.action.sections.pop()
        },
    },
    data(){
        return {
            objAux: {},
            operators: [],
            departments: [],
            menu: {},
            showDialogTextarea: false,
            showDialogHeader: false,
            showDialogFooter: false,
            buttonSelected: null,
            tags: [
                {
                    name: '%NOME_CLIENTE%',
                    description: 'Identifica nome do contato',
                },
                {
                    name: '%OPERADOR%',
                    description: 'Identifica nome do operador (caso exista operador em atendimento com o contato)',
                },
                {
                    name: '%DTN%',
                    description: 'Dia, Tarde ou Noite',
                },
                // {
                //     name: '%Número%',
                //     description: 'Identifica número do contato',
                // },
                // {
                //     name: '#',
                //     description: 'Voltar ao Menu Anterior',
                // },
                // {
                //     name: '*',
                //     description: 'Finaliza Atendimento no Painel Principal / Volta ao Menu Principal',
                // },
            ],
            i18n: {
                search: 'Pesquisar...',
                categories: {
                    Activity: "Atividades",
                    Flags: "Bandeiras",
                    Foods: "Comida",
                    Frequently: "Frequentes",
                    Objects: "Objetos",
                    Nature: "Natureza",
                    Peoples: "Pessoas",
                    Symbols: "Símbolos",
                    Places: "Locais"
                }
            }
        }
    }
}
</script>

<style>
    .addimage .custom-file-input{
        display: none;
    }
    .addimage .custom-file-label{
        display: block;
        cursor: pointer;
    }
</style>

<style scoped>
    .cardContent{
        padding: 30px;
    }
    .cardHeader{
        padding: 30px;
        display: flex;
        align-items: center;
        border-bottom: 0.5px solid #eee;
    }
    .cardnumber{
        margin-right: 15px;
        color: #fff;
        display:inline-block;
        font-size:25px;
        font-weight: 500;
    }
    .cardnumber:before,
    .cardnumber:after {
        content:'\200B';
        display:inline-block;
        line-height:0px;

        padding-top:50%;
        padding-bottom:50%;
    }
    .cardnumber:before {
        padding-left:12px;
    }
    .cardnumber:after {
        padding-right:12px;
    }
    .toggle-emoji{
        position: absolute;
        bottom: 0.3em;
        right: 1em;
        background-color: transparent;
        border: none;
        color: #aaa;
        box-shadow: none !important;
        cursor: default;
    }
    .emoji-picker{
        position: absolute;
        bottom: 40px;
        right: 0;
        width: 250px;
        z-index: 1;
    }
    .iconemojipicker{
        bottom: 0;
    }
    .cardmainmenubtns{
        display: flex;
        justify-content: flex-end;
    }
    .managemenubtngreen:not(:last-child){
        margin-right: 10px;
    }
    .managemenubtngreen{
        background-color: hsl(142deg 38% 59%);
        border-color: hsl(142deg 38% 59%);
        padding: 5px 20px;
    }
    .managemenubtngrey{
        background-color: hsl(208deg 7% 46%);
        border-color: hsl(208deg 7% 46%);
        padding: 5px 20px;
    }
    .managemenubtnpink{
        background-color: hsl(339deg 81% 69%);
        border-color: hsl(339deg 81% 69%);
        padding: 5px 20px;
    }
    .managemenubtnred{
        background-color: hsl(360deg 83% 69%);
        border-color: hsl(360deg 83% 69%);
        padding: 5px 20px;
    }
    .managemenubtnwhite{
        background-color: #fff;
        color: #777;
        border-color: #eee;
    }
    .managemenubtnwhite:hover{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    select, input, textarea{
        border-color: #eee;
        color: #555;
    }
    .cardpresentationimg{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        color: #fff;
        background-color: hsl(249deg 9% 28%);
        padding: 79px;
        cursor: pointer;
        width: 100%;
    }
    .custom-file-label:hover .cardpresentationimg{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
</style>